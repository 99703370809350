import React from 'react';
import './checkboxgroup.css';

const CheckboxGroup = ({ items, selectedItems, onItemSelected }) => {

  const isItemSelected = (item) => {
    return selectedItems.some(selectedItem => selectedItem.id === item.id);
  };

  const handleItemClick = (item) => {
    const updatedSelectedItems = isItemSelected(item)
      ? selectedItems.filter((selectedItem) => selectedItem.id !== item.id)
      : [...selectedItems, item];
    onItemSelected(updatedSelectedItems);
    console.log(updatedSelectedItems);
  };

  return (
    <div className="checkbox-group">
      {items.map((item) => (
        item.visible ? (
          <label
            key={item.id}
            className={`body-small checkbox-item ${isItemSelected(item) ? 'selected' : ''}`}
          >
            <input
              type="checkbox"
              checked={isItemSelected(item)}
              onChange={() => handleItemClick(item)}
            />
            {item.label}
          </label>
        ) : null
      ))}
    </div>
  );
};

export default CheckboxGroup;
