'use client'
import React from 'react';
import './customselect.css'; // Import the custom CSS file

const Select = ({ options, onSelect }) => {
  return (
    // <div className="custom-select">
      <select onChange={(e) => onSelect(e.target.value)}>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    /* </div> */
  );
};

export default Select;