import React, { useState, useEffect } from 'react';
import gsap from 'gsap';
import './pageview.css'; // Create this CSS file for styling

const PageView = ({ pages, currentPageIndex, onPageChange, controller, controllerInitFunc, func }) => {
  const [currentPage, setCurrentPage] = useState(currentPageIndex);
  const pageController = gsap.timeline({ paused: true });

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, pages.length - 1));
  };

  useEffect(() => {
    if (onPageChange) {
      onPageChange(currentPage);
    }

    const pagesArray = Array.from(document.querySelectorAll('.page'));

    pageController
      .to(
        pagesArray[currentPage],
        {
          xPercent: 0,
          opacity: 1,
          duration: 0.3,
          ease: 'power3.inOut',
        }
      )
      .to(
        pagesArray[(currentPage - 1 + pages.length) % pages.length],
        {
          xPercent: -100,
          opacity: 0,
          duration: 0.3,
          ease: 'power3.inOut',
        },
        '<'
      )
      .to(
        pagesArray[(currentPage + 1) % pages.length],
        {
          xPercent: 100,
          opacity: 0,
          duration: 0.3,
          ease: 'power3.inOut',
        },
        '<'
      );
  }, [currentPage, pages, onPageChange]);

  useEffect(() => {
    pageController.play();
  }, [currentPage]);

  useEffect(() => {
    controllerInitFunc(handleNextPage, handlePrevPage)
  }, [])

  // External method to set the current page
  const setPage = (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  return (
    <div className="page-view">
      <div className="page-container">
        {pages.map((page, index) => (
          <div
            key={index}
            className={`page ${index === currentPage ? 'active' : ''}`}
          >
            {page}
          </div>
        ))}
      </div>
      {/* <button onClick={handlePrevPage}>Previous</button>
      <button onClick={handleNextPage}>Next</button> */}
    </div>
  );
};

export default PageView;
