import { getStorage, ref, uploadBytesResumable } from 'firebase/storage';

const uploadFiles = async (files, uuid, onProgress) => {
    try {
        const storage = getStorage();
        const orderFilesRef = ref(storage, `orderFiles/${uuid}`);

        for (const file of files) {
            if (file === null) {
                continue; // Skip null items
            }

            const fileRef = ref(orderFilesRef, file.name);
            const uploadTask = uploadBytesResumable(fileRef, file);

            uploadTask.on('state_changed', (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                onProgress({ progress, filename: file.name }); // Pass fileName here
            });

            await uploadTask;
        }

        return { success: true };
    } catch (error) {
        return {
            error: {
                code: error.code,
                message: error.message,
            },
        };
    }
};

export default uploadFiles;
