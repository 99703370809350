import React, { useEffect } from 'react';
import { gsap, Bounce } from 'gsap';
import '../styles/orderconfirmed/orderconf.css';
import { Link, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

export default function OrderConfirmed() {

    const { ref } = useParams()

    function anim() {
        // Create a timeline for animations
        const timeline = gsap.timeline();

        // Animation for confetti image (scale in with bounce)
        timeline.to('.confetti', {
            opacity: 1,
            scale: 1,
            ease: Bounce.easeOut,
            duration: 1,
        });

        // Animation for the rest of the content (fade in)
        timeline.to('.order-conf-wrapper > .container > *', {
            opacity: 1,
            y: 0,
            stagger: 0.2,
            duration: 0.6,
        });
    }

    useEffect(() => {
        anim()
        // console.log(ref);
    }, []);

    return (
        <>
            <ToastContainer position='bottom-center' theme='colored' autoClose={3000} />
            <div className="order-conf-wrapper">
                <div className="container">
                    <img src="/images/Confetti.png" alt="" style={{ opacity: 0, transform: 'scale(0)' }} className="confetti mb-3" />
                    <h2 className='mb-2'>Order confirmed!</h2>
                    <div className="body-small mb-3">We will email you when your screenshots are ready, typically within 48 hours.</div>
                    {ref
                        ? <div className="title-3 mb-3">Send your app files to <a href="mailto:amithchalil@gmail.com">this address</a> and mention the reference number below.</div>
                        : null}

                    {ref
                        ? <div className="ref-box mb-3" onClick={() => { navigator.clipboard.writeText(ref); toast("Copied!", { type: 'success' }) }}>
                            <div className="ref title-4">63093001-a006-4a6e-9edf-4d238b2802ca</div>
                            <div className="ref-helper body-small">Copy</div>
                        </div>
                        : null}
                    <Link to="/"><button className="button primary">Go to the homepage</button></Link>
                </div>
            </div>
        </>
    )
}