import { useEffect, useState } from 'react';
import InputWithLabel from '../components/InputWithLabel/InputWithLabel';
import PageView from '../components/PageView/PageView';
import ToggleGroup from '../components/ToggleGroup/ToggleGroup';
import '../styles/purchasepage/purchasepage.css';
import CheckboxGroup from '../components/CheckboxGroup/CheckboxGroup';
import PageController from '../components/PageView/PageController';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from '../components/Spinner/Spinner';
import { uploadOrderData } from '../firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import uploadFiles from '../firebase/storage';
import isEmail from 'validator/lib/isEmail';
import isURL from 'validator/lib/isURL';
import Select from '../components/Select/Select';
import { useNavigate } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebase/init';

export default function PurchasePage() {

    const [currentPage, setCurrentPage] = useState(0);
    const [controller, setController] = useState(null);

    // Router for navigation
    const router = useNavigate();

    // Form state variables
    const [error, setError] = useState(null);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [appName, setAppName] = useState("");
    const [requirements, setReqs] = useState([]);
    const [selectedOption, setSelectedOption] = useState("Market-ready");
    const [androidDev1, setAndroidDev1] = useState(null);
    const [androidDev2, setAndroidDev2] = useState(null);
    const [iOSDev1, setiOSDev1] = useState(null);
    const [iOSDev2, setiOSDev2] = useState(null);
    const [androidTab, setAndroidTab] = useState(null);
    const [iOSTab, setiOSTab] = useState(null);
    const [sendLater, setSendLater] = useState([])
    const [apkFile, setApkFile] = useState(null);
    const [iosFile, setIOSFile] = useState(null);
    const [webLink, setWebLink] = useState("");
    const [orderDesc, setOrderDesc] = useState("");

    // Loading state variable
    const [loading, setLoading] = useState(false);
    const [stage, setStage] = useState("Saving your order...");
    const [upload, setUpload] = useState([]);

    let reqOptions = [
        { id: 1, label: "Android screenshots", visible: true },
        { id: 2, label: 'iOS screenshots', visible: true },
        { id: 3, label: '12.9" iPad', visible: selectedOption.trim() === 'Premium' },
        { id: 4, label: 'Android tablet', visible: selectedOption.trim() === 'Premium' },
        { id: 5, label: 'Desktop browser screenshots', visible: selectedOption !== "Basic" },
        { id: 6, label: 'Mobile browser screenshots', visible: selectedOption !== "Basic" },
    ]

    let uploadLaterOptions = [
        { id: 1, label: "I'll email the files later", visible: true }
    ]

    //Options for Android device
    let androidDevices = [
        "Select a device",
        "Pixel 7 Pro",
        "Pixel 6 Pro"
    ]

    //Options for iOS device
    let iOSDevices = [
        "Select a device",
        "iPhone 14 Pro",
        "iPhone 13 Pro",
        "iPhone 12",
        "iPhone 11 Pro"
    ]

    //Options for Android tablets
    let androidTabs = [
        "Select a device",
        "Samsung Galaxy Tab"
    ]

    //Options for iPads
    let iPads = [
        "Select a device",
        "12.9\" iPad"
    ]

    const handleSelect = (option) => {
        setSelectedOption(option)
    }

    const isItemSelected = (label) => {
        return requirements.some(requirement => requirement.label === label);
    };

    const willSendLater = () => {
        return sendLater.some(item => item.label === "I'll email the files later");
    };

    const setHandlers = (nexthandler, prevhandler) => {
        setController(new PageController(nexthandler, prevhandler))
    }

    const handlePageChange = (newPageIndex) => {
        setCurrentPage(newPageIndex);
    };

    useEffect(() => {
        toast(error, { type: "error" })
        setTimeout(() => setError(null), 3000)
    }, [error])

    // useEffect(() => console.log(upload), [upload])

    const validateForms = () => {
        if (currentPage === 0) {
            //Validate name and email
            if (name === "") {
                setError("Name is required.")
                return
            }
            if (email === "") {
                setError("Email is required.")
                return
            }
            if (!isEmail(email)) {
                setError("Email address is invalid.")
                return
            }
            if (appName === "") {
                setError("App name is required.")
                return
            }
            controller.goToNextPage()
        }

        if (currentPage === 1) {
            //Validate package
            if (!selectedOption) {
                setError("Please select a package.")
                return
            }
            if (requirements.length === 0) {
                setError("You have not selected any requirements.")
                return
            }
            controller.goToNextPage()
        }

        if (currentPage === 2) {
            controller.goToNextPage()
        }

        if (currentPage === 3) {
            //Validate app files and web links
            if (!apkFile && isItemSelected("Android screenshots") && !willSendLater()) {
                setError("Please upload an .apk file for your app.")
                return
            }
            if (!iosFile && isItemSelected("iOS screenshots") && !willSendLater()) {
                setError("Please upload an iOS file for your app.")
                return
            }
            if (webLink === "" && (isItemSelected("Desktop browser screenshots") || isItemSelected("Mobile browser screenshots"))) {
                setError("Please provide a link to your website.")
                return
            }
            if (!isURL(webLink) && (isItemSelected("Desktop browser screenshots") || isItemSelected("Mobile browser screenshots"))) {
                setError("Provided URL is invalid.")
                return
            }
            controller.goToNextPage()
        }

        if (currentPage === 4) {
            //Validate order description
            if (orderDesc === "") {
                setError("Your order description is empty.")
                return
            }
            uploadData()
            logEvent(analytics, "order_placed")
        }
        // controller.goToNextPage()
    }

    // Function to upload data to Firestore and Cloud Storage
    const uploadData = async () => {
        setLoading(true)
        const uuid = uuidv4();
        let dataObj = {
            name: name,
            email: email,
            app_name: appName,
            package: selectedOption,
            requirements: requirements,
            devices: {
                android: [androidDev1, androidDev2],
                ios: [iOSDev1, iOSDev2],
                tablet: [androidTab, iOSTab]
            },
            payment_status: "Pending",
            createdOn: new Date(),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            uuid: uuid,
            web_link: webLink,
            order_desc: orderDesc
        }
        const result = await uploadOrderData(dataObj);

        if (result.ref) {
            if (!willSendLater()) {
                setStage("Uploading files...")
                // console.log(result.ref)

                // Now moving on to uploading files
                const uploadResult = await uploadFiles([apkFile, iosFile], uuid, ({ progress, filename }) => {
                    setUpload((prevProgress) => {
                        // Find the existing progress object for the current file name
                        const existingProgress = prevProgress.find((item) => item.filename === filename);
                        if (existingProgress) {
                            // Update the progress for the current file name
                            return prevProgress.map((item) =>
                                item.filename === filename ? { ...item, progress } : item
                            );
                        } else {
                            // Add a new progress object for the current file name
                            return [{ progress, filename }];
                        }
                    });
                })

                if ("success" in uploadResult) {
                    router('/order-confirmed')
                } else if ('error' in result) {
                    console.error('Error uploading files:', result.error.message);
                    setLoading(false);
                    setError(result.error.message)
                }
            } else {
                logEvent(analytics, "chose_to_upload_later")
                router(`/order-confirmed/${uuid}`)
            }
        } else {
            setLoading(false)
            console.log(result.error.code);
            setError(result.error.message)
        }
    }



    return <>
        {/* Toast Container */}
        <ToastContainer position="bottom-center" theme="colored" autoClose={3000} />

        <div className="purchase-form-container">
            <div className="container">
                <h2 className="page-heading mt-4 mb-4">Order your package</h2>
                {!loading
                    ? <PageView pages={[
                        <form key={1} action="" className="form">
                            <div className="form-section">
                                <h3 className="section-header">
                                    <span className="step-no">Step 1: &nbsp; </span>
                                    <span className="step">Contact details</span>
                                </h3>
                                <div className="body-small mt-3">Enter your contact details and your app name. We will contact you on the provided email if necessary.</div>

                                <div className="form-wrapper mt-3">
                                    <InputWithLabel onChange={(e) => setName(e.target.value)} required type="text" placeholder="John Doe" label="Your name" />
                                    <InputWithLabel onChange={(e) => setEmail(e.target.value)} required type="email" placeholder="john.doe@example.com" label="Email address" />
                                    <InputWithLabel onChange={(e) => setAppName(e.target.value)} required type="text" placeholder="My App" label="Your app's name" />
                                </div>
                                {error ? <div className="error body-small">{error}</div> : null}
                            </div>
                        </form>,
                        <form key={2} action="" className="form">
                            <div className="form-section">
                                <h3 className="section-header">
                                    <span className="step-no">Step 2: &nbsp; </span>
                                    <span className="step">Select package</span>
                                </h3>
                                <div className="body-small mt-3">Choose a package according to your needs.</div>

                                <div className="form-wrapper mt-3">
                                    <ToggleGroup options={["Basic", "Market-ready", "Premium "]} selectedOption={selectedOption} onSelect={handleSelect} />

                                    <div className="title-3 mt-4 mb-2">Choose your requirements</div>
                                    <CheckboxGroup
                                        items={reqOptions}
                                        selectedItems={requirements}
                                        onItemSelected={setReqs} />
                                </div>
                            </div>
                        </form>,
                        <form key={3} action="" className="form">
                            <div className="form-section">
                                <h3 className="section-header">
                                    <span className="step-no">Step 3: &nbsp; </span>
                                    <span className="step">Select devices</span>
                                </h3>
                                <div className="body-small mt-3">Choose the devices you want to use for the screenshots.</div>

                                <div className="form-wrapper mt-3">
                                    {isItemSelected("Android screenshots")
                                        ? <><div className="title-3">Android</div>
                                            <div className="row mt-2"><span className="body-small">Device 1: </span> <Select options={androidDevices} onSelect={setAndroidDev1} /></div>
                                            {(selectedOption.trim() === "Market-ready" || selectedOption.trim() === "Premium") ? <div className="row mt-2"><span className="body-small">Device 2: </span> <Select options={androidDevices} onSelect={setAndroidDev2} /></div> : null}</>
                                        : null}

                                    {isItemSelected("iOS screenshots")
                                        ? <><div className="title-3 mt-3">iOS</div>
                                            <div className="row mt-2"><span className="body-small">Device 1: </span> <Select options={iOSDevices} onSelect={setiOSDev1} /></div>
                                            {(selectedOption.trim() === "Market-ready" || selectedOption.trim() === "Premium") ? <div className="row mt-2"><span className="body-small">Device 2: </span> <Select options={iOSDevices} onSelect={setiOSDev2} /></div> : null}</>
                                        : null}

                                    {(selectedOption.trim() === "Premium")
                                        ? <>
                                            <div className="title-3 mt-3">Large screens</div>
                                            {isItemSelected("Android tablet") ? <div className="row mt-2"><span className="body-small">Android: </span> <Select options={androidTabs} onSelect={setAndroidTab} /></div> : null}
                                            {isItemSelected("12.9\" iPad") ? <div className="row mt-2"><span className="body-small">iOS: </span> <Select options={iPads} onSelect={setiOSTab} /></div> : null}</>
                                        : null}
                                </div>
                            </div>
                        </form>,
                        <form key={4} action="" className="form">
                            <div className="form-section">
                                <h3 className="section-header">
                                    <span className="step-no">Step 4: &nbsp; </span>
                                    <span className="step">Upload files</span>
                                </h3>
                                <div className="body-small mt-3">Upload your app packages.</div>

                                <div className="mt-2"><CheckboxGroup items={uploadLaterOptions} selectedItems={sendLater} onItemSelected={setSendLater} /></div>

                                {willSendLater()
                                    ? <div className="title-3 mt-3">Continue to the next step.</div>
                                    : null}

                                <div className="form-wrapper mt-3">
                                    {(isItemSelected("Android screenshots") && !willSendLater())
                                        ? <div className='mb-4'>
                                            <div className="title-4 mb-2">.apk file</div>
                                            {apkFile ? <div className="file-row title-3 my-2">{apkFile.name} <button onClick={() => setApkFile(null)} className='text-button remove-button'>Remove</button></div> : null}
                                            {apkFile ? null : <button onClick={(e) => { e.preventDefault(); document.querySelector("#apk").click() }} className="file-upload-button">Upload</button>}
                                            <input accept='.apk' onChange={(e) => setApkFile(e.target.files[0])} type="file" name="apk" id="apk" hidden />
                                        </div>
                                        : null}

                                    {(isItemSelected("iOS screenshots") && !willSendLater())
                                        ? <div className='mb-4'>
                                            <div className="title-4 mb-2">iOS file</div>
                                            {iosFile ? <div className="file-row title-3 my-2">{iosFile.name} <button onClick={() => setIOSFile(null)} className='text-button remove-button'>Remove</button></div> : null}
                                            {iosFile ? null : <button onClick={(e) => { e.preventDefault(); document.querySelector("#ios").click() }} className="file-upload-button">Upload</button>}
                                            <input onChange={(e) => setIOSFile(e.target.files[0])} type="file" name="ios" id="ios" hidden />
                                        </div>
                                        : null}

                                    {(isItemSelected("Desktop browser screenshots") || isItemSelected("Mobile browser screenshots"))
                                        ? <div className='mb-4'>
                                            <div className="title-4 mb-2">Link to website/web app</div>
                                            <InputWithLabel onChange={(e) => setWebLink(e.target.value)} type='text' placeholder='https://example.com' />
                                        </div>
                                        : null
                                    }
                                </div>
                            </div>
                        </form>,
                        <form key={5} action="" className="form">
                            <div className="form-section">
                                <h3 className="section-header">
                                    <span className="step-no">Step 5: &nbsp; </span>
                                    <span className="step">Order description</span>
                                </h3>
                                <div className="body-small mt-3">Describe your order. What screens do you need? How do you want the screenshots to look? <br /> Feel free to include any links for reference.</div>

                                <div className="form-wrapper mt-3">
                                    <textarea onChange={(e) => setOrderDesc(e.target.value)} name="description" placeholder='I want screenshots of x screens in my app...' id="desc" rows="10"></textarea>
                                </div>
                            </div>
                        </form>
                    ]}
                        currentPageIndex={currentPage}
                        onPageChange={handlePageChange}
                        controller={controller}
                        controllerInitFunc={setHandlers}
                    />
                    : <div className='loading'>
                        <div className="loading-text"><Spinner size={30} /><span className='title-3'>{stage}</span></div>
                        {upload !== []
                            ? <>
                                {upload.map(({ progress, filename }) => (
                                    <div className='body-small mt-2' key={filename}>
                                        Uploading {filename}: {progress.toFixed(2)}%
                                    </div>
                                ))}
                            </> : null}
                    </div>}
                {!loading
                    ? <div className="button-row">
                        <button disabled={loading} className="button secondary" onClick={() => controller.goToPreviousPage()}>Previous</button>
                        <button disabled={loading} className="button primary" onClick={validateForms}>{currentPage === 4 ? "Submit order" : "Next"}</button>
                    </div>
                    : null}
            </div>
        </div>
    </>
}