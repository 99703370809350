import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { app } from './init';

const db = getFirestore(app);

export const uploadOrderData = async (data) => {
    try {
        var docRef = await addDoc(collection(db, "orders"), data);
        return { ref: docRef.id }
    } catch (e) {
        return {
            e: {
                code: e.code,
                message: e.message
            }
        };
    }
}

