import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDK52dnOhZJ2liHQZ2CyD19XexohRM0JuU",
  authDomain: "screenshotbox.firebaseapp.com",
  projectId: "screenshotbox",
  storageBucket: "screenshotbox.appspot.com",
  messagingSenderId: "397024489100",
  appId: "1:397024489100:web:2da5e6a5560770c5a9d5a9",
  measurementId: "G-1KLNY4VMDX"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);