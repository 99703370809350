import React from 'react';

// Styles
import '../styles/homepage/hero.css';
import '../styles/homepage/packages.css';
import '../styles/homepage/footer.css';
import { Link } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebase/init';

export default function Home() {
  return (
    <>
      {/* Navbar */}
      <nav className="navbar">
        <Link to='/' onClick={() => logEvent(analytics, "nav_logo_used")} style={{color: 'black', textDecoration: 'none'}}><div className="navbar-logo">ScreenshotBox</div></Link>
        <div className="navbar-links">
          <a href="#packages">View packages</a>
          <Link to="/purchase-screenshots">Purchase now</Link>
        </div>
        <div className="navbar-mobile-menu">

          {/* <button className="menu-icon"><span className="dot"></span>Menu</button> */}
        </div>
      </nav>

      {/* <!-- Hero Section --> */}
      <section className="hero">
        <div className="hero_container container">
          <img className="hero_image_mockup" src="/images/Hero Mockup.webp" alt="" />
          <div className="hero_content">
            <h1>Get market-ready screenshots for your apps and websites.</h1>
            <a href="#packages"><button className="button mt-4"  onClick={() => logEvent(analytics, "cta_clicked")}>View packages</button></a>
          </div>
        </div>
      </section>

      {/* About section */}
      <section className="about my-5">
        <div className="container">
          <h2 className='mb-2'>Let us make your life easier!</h2>
          <div className="body-small">
          We take the hassle out of enhancing your app's presentation. No more DIY efforts or testing. Just upload your app, share your preferences, and we'll handle the rest. Elevate your app's visual appeal effortlessly with ScreenshotBox.
          </div>
        </div>
      </section>

      {/* Packages section */}
      <section className="packages mt-5" id="packages">
        <div className="container">
          <h2 className="mb-4">Choose a package</h2>

          <div className="package-list">
            <div className="card mb-5"> {/* Basic package card */}
              <div className="card-image-wrapper">
                <img src="./images/Preview_Basic.webp" alt="" className="card-image" />
              </div>
              <div className="card-content my-2">
                <div className="title-section">
                  <h3 className="card-title">Basic</h3>
                  <span className="price">$29</span>
                </div>
                <div className="body-small card-description mt-1">Just the essentials.</div>
                <Link to="/purchase-screenshots"><button className="button wide mt-3">Purchase now</button></Link>
                <div className="divider my-3"></div>
                <div className="benefits mt-3">
                  <div className="card-subheader title-3">This package includes:</div>
                  <div className="body-small list-item"><img src="/images/Checkmark.png" alt="" />1 iOS device</div>
                  <div className="body-small list-item"><img src="/images/Checkmark.png" alt="" />2 screenshots per device</div>
                  <div className="body-small list-item"><img src="/images/Checkmark.png" alt="" />1 Android device</div>
                </div>
              </div>
            </div>

            <div className="card mb-5"> {/* Market-ready package card */}
              <div className="card-image-wrapper">
                <img src="./images/Preview_Market.webp" alt="" className="card-image" />
              </div>
              <div className="card-content my-2">
                <div className="title-section">
                  <h3 className="card-title">Market-ready</h3>
                  <span className="price">$69</span>
                </div>
                <div className="body-small card-description mt-1">Everything you need to submit to Google Play and the App Store.</div>
                <Link to="/purchase-screenshots"><button className="button wide mt-3">Purchase now</button></Link>
                <div className="divider my-3"></div>
                <div className="benefits mt-3">
                  <div className="card-subheader title-3">This package includes:</div>
                  <div className="body-small list-item"><img src="/images/Checkmark.png" alt="" />2 Android devices</div>
                  <div className="body-small list-item"><img src="/images/Checkmark.png" alt="" />2 iOS devices</div>
                  <div className="body-small list-item"><img src="/images/Checkmark.png" alt="" />5 screenshots per device</div>
                  <div className="body-small list-item"><img src="/images/Checkmark.png" alt="" />2 desktop browser screenshots</div>
                  <div className="body-small list-item"><img src="/images/Checkmark.png" alt="" />2 mobile browser screenshots</div>
                </div>
              </div>
            </div>

            <div className="card mb-5"> {/* Premium package card */}
              <div className="card-image-wrapper">
                <img src="./images/Preview_Premium.webp" alt="" className="card-image" />
              </div>
              <div className="card-content my-2">
                <div className="title-section">
                  <h3 className="card-title">Premium</h3>
                  <span className="price">$129</span>
                </div>
                <div className="body-small card-description mt-1">Everything you need for Google Play and the App Store, plus much more.</div>
                <Link to="/purchase-screenshots"><button className="button wide mt-3">Purchase now</button></Link>
                <div className="divider my-3"></div>
                <div className="benefits mt-3">
                  <div className="card-subheader title-3">This package includes:</div>
                  <div className="body-small list-item"><img src="/images/Checkmark.png" alt="" />2 Android devices</div>
                  <div className="body-small list-item"><img src="/images/Checkmark.png" alt="" />2 iOS devices</div>
                  <div className="body-small list-item"><img src="/images/Checkmark.png" alt="" />12.9&quot; iPad</div>
                  <div className="body-small list-item"><img src="/images/Checkmark.png" alt="" />Android tablet</div>
                  <div className="body-small list-item"><img src="/images/Checkmark.png" alt="" />8 screenshots per device</div>
                  <div className="body-small list-item"><img src="/images/Checkmark.png" alt="" />2 desktop browser screenshots</div>
                  <div className="body-small list-item"><img src="/images/Checkmark.png" alt="" />2 mobile browser screenshots</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer>
        <div className="container body-small" style={{color: 'white'}}>Copyright 2023 ScreenshotBox | <Link to="/privacy-policy">Privacy Policy</Link></div>
      </footer>
    </>
  )
}
