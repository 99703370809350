import React from 'react';

export default function PrivacyPolicy() {
    return (
        <>
            <div className="privacy-policy" style={{ margin: '50px 0' }}>
                <div className="container">
                    <h3>ScreenshotBox - Privacy Policy</h3>
                    <div className="title-2">Last updated on 21st August 2023</div>

                    <div className="section mt-3">
                        <div className="title-3">Introduction</div>
                        <div className="body-small mt-1">
                            Welcome to ScreenshotBox! This Privacy Policy outlines how we collect, use, share, and protect the personal information of our users. By accessing or using our website [screenshotbox.com], you agree to the terms and practices described in this policy.
                        </div>
                    </div>

                    <div className="section mt-3">
                        <div className="title-3">Information We Collect</div>
                        <div className="body-small mt-1">
                            We collect the following types of information from our users: <br />

                            Personal Information: When you place an order through our website, we collect your name, email address, and app name. Additionally, we provide fields for you to upload your app packages. <br />

                            Usage Data: We may collect information about how you use our website, including pages visited, time spent on the site, and interactions with features.
                        </div>
                    </div>

                    <div className="section mt-3">
                        <div className="title-3">Use of Information</div>
                        <div className="body-small mt-1">
                            We use the collected information for the following purposes:<br />

                            Order Fulfillment: We use your name, email, and app details to process your order and deliver the requested screenshot designs to you via email. <br />

                            Communication: We may use your email to provide order updates, respond to inquiries, and provide customer support. <br />

                            Improve Services: Usage data helps us analyze and enhance our website&apos;s performance and user experience. <br />

                            Legal Compliance: We may use and disclose information as required by applicable laws, regulations, or legal proceedings. <br />
                        </div>
                    </div>

                    <div className="section mt-3">
                        <div className="title-3">Data Security</div>
                        <div className="body-small mt-1">
                            We take reasonable measures to protect the information collected. However, no method of data transmission or storage is entirely secure. We cannot guarantee the security of your information, so please use the service at your own risk.
                        </div>
                    </div>

                    <div className="section mt-3">
                        <div className="title-3">Sharing of Information</div>
                        <div className="body-small mt-1">
                            We do not sell, trade, or rent your personal information to third parties. We may share your information with service providers who assist us in delivering our services, but they are bound by confidentiality obligations.
                        </div>
                    </div>

                    <div className="section mt-3">
                        <div className="title-3">Age requirement</div>
                        <div className="body-small mt-1">
                            Our services are intended for individuals aged 18 and older. We do not knowingly collect personal information from individuals under 18. If you are a parent or guardian and believe your child has provided us with information, please contact us to have it removed.
                        </div>
                    </div>

                    <div className="section mt-3">
                        <div className="title-3">Changes to this Policy</div>
                        <div className="body-small mt-1">
                            We may update this Privacy Policy from time to time. The &quot;Last Updated&quot; date at the top will indicate the most recent changes. By continuing to use our services after any changes, you agree to the updated policy.
                        </div>
                    </div>

                    <div className="section mt-3">
                        <div className="title-3">Contact Us</div>
                        <div className="body-small mt-1">
                            If you have questions or concerns about this Privacy Policy, please contact us at <a href="mailto:amithchalil@gmail.com">amithchalil@gmail.com</a>. <br /> <br />

                            By using ScreenshotBox&apos;s services, you acknowledge that you have read and understood this Privacy Policy and agree to the practices described herein.
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}