import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';

//Global styles
import './styles/global/colours.css';
import './styles/global/components.css';
import './styles/global/input.css';
import './styles/global/margins.css';
import './styles/global/motifs.css';
import './styles/global/navbar.css';
import './styles/global/typography.css';

import Home from './routes/HomePage';
import PrivacyPolicy from './routes/PrivacyPolicy';
import PurchasePage from './routes/PurchasePage';
import OrderConfirmed from './routes/OrderConfirmed';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />
  },
  {
    path: '/purchase-screenshots',
    element: <PurchasePage />
  },
  {
    path: '/order-confirmed/',
    element: <OrderConfirmed />
  },
  {
    path: '/order-confirmed/:ref',
    element: <OrderConfirmed />
  }
])

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
