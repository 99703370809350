import ToggleButton from "./ToggleButton";
import './togglegroup.css';

export default function ToggleGroup({ options, selectedOption, onSelect }) {
    return (
        <div className="toggle-button-group">
            {options.map((option) => (
                <ToggleButton
                    key={option}
                    option={option}
                    isSelected={selectedOption === option}
                    onSelect={onSelect}
                />
            ))}
        </div>
    );
}