export default function ToggleButton({ option, isSelected, onSelect }) {
    const handleClick = (event) => {
        event.preventDefault();
        onSelect(option);
    };

    return (
        <button
            className={`toggle-button ${isSelected ? 'selected' : ''}`}
            onClick={handleClick}
        >
            {option}
        </button>
    );
};